
.rr-form {
  .country-selector__input,.input-tel__input{
    border-color: #e2e7f1;
  }
  .input-tel.is-valid{
    .input-tel__input{
      border-color: #e2e7f1 !important;
    }
    label{
      color: #e2e7f1 !important;
    }
  }
  .input-tel__input::placeholder{visibility: hidden}
}
